
import {
  ConsultantContractPayment,
  ConsultantInvoice,
  ConsultantMissionExpenseInput,
  ConsultantProfileFragmentFragment,
  CreateConsultantInvoiceMutation,
  CreateConsultantInvoiceMutationMutation,
  CreateConsultantMissionExpenseMutation,
  CreateConsultantMissionExpenseMutationMutation,
  CreateConsultantMissionExpenseMutationMutationVariables,
  SubmitConsultantInvoiceMutation,
  UpdateConsultantMissionExpenseMutation,
  SubmitConsultantInvoiceMutationMutation,
  UpdateConsultantMissionExpenseMutationMutation,
  UpdateConsultantMissionExpenseMutationMutationVariables,
  UploadFileInput,
} from "@/gql"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import Banner from "@/components/banners/Banner.vue"
import { useProfile } from "@/hooks/useProfile"
import { Maybe } from "graphql/jsutils/Maybe"

type Expense = {
  id?: string | null
  key: string
  description: string
  quantity: number | null
  unitCost: number | null
  uploadFile: UploadFileInput | null | undefined
  hasChanges?: boolean
}

@Component({
  components: {
    Banner,
  },
})
export default class EditConsultantInvoiceForm extends Vue {
  @Prop() readonly defaultPayment?: ConsultantContractPayment

  @Prop()
  readonly invoice?: ConsultantInvoice

  @Prop() readonly metadata?: Record<string, any>

  @Prop({ default: "AFG Ghana" }) readonly contractingEntity?: string

  defaultForm: {
    billingAccountType: string
    paymentDescription: string | null
    billingAccountId: string | null | undefined
    expenses: Omit<Expense, "key">[]
  } = {
    billingAccountType: "Payoneer",
    billingAccountId: null,
    paymentDescription: null,
    expenses: [],
  }

  expenses: Expense[] = []
  loading = false

  get updating() {
    return !!this.invoice
  }

  form = { ...this.defaultForm }

  get missionLifeCycleName(): string {
    return (
      (this.invoice && this.invoice.missionLifecycle.name) ||
      (this.metadata && this.metadata.missionLifecycle_name) ||
      "N/A"
    )
  }

  get missionStaffingPositionName(): string {
    return (
      (this.invoice && this.invoice?.missionStaffingPlacement?.missionStaffingPosition?.name) ||
      (this.metadata && this.metadata.missionStaffingPosition_name) ||
      "N/A"
    )
  }

  getTotal(quantity: number, unitCost: number): number {
    return parseInt((quantity || 0) as any) * parseFloat((unitCost || 0) as any) || 0
  }

  onAddExpense() {
    this.expenses.push({
      key: this.generateRand(4),
      description: "",
      quantity: null,
      unitCost: null,
      uploadFile: null,
    })
  }

  onRemoveExpense(index: number) {
    this.expenses.splice(index, 1)
  }

  get grandTotal(): string {
    const expensesTotal = this.expenses
      .map((e) => this.getTotal(e.quantity || 0, e.unitCost || 0))
      .reduce((sum, cur) => sum + cur, 0)
    const linkedPayment = this.defaultPayment?.amount.amount || 0

    return (expensesTotal + linkedPayment).toFixed(2)
  }

  async submit() {
    if (this.expenses.some((e) => !e.uploadFile)) {
      this.addError("Please attach all receipts")
      return
    }

    this.form.expenses = this.expenses.map(({ key, ...rest }) => rest)

    if (this.invoice) {
      await this.submitInvoice()
      return
    }
    // creating
    if (this.metadata) {
      if (!this.expenses.length) {
        this.addError("Please add an expense to continue")
        return
      }

      await this.createInvoice()
    }
  }

  async createInvoice() {
    this.loading = true

    const result = await this.mutate<CreateConsultantInvoiceMutationMutation>({
      mutation: CreateConsultantInvoiceMutation,
      variables: {
        ...this.form,
        consultantId: this.metadata?.consultantId,
        missionLifecycleId: this.metadata?.missionLifecycleId,
        missionStaffingPlacementId: this.metadata?.missionStaffingPlacementId,
        billingAccountType:
          this.form.billingAccountType == "Payoneer"
            ? "ConsultantPayoneerAccount"
            : "ConsultantBankAccount",
      },
    })
    this.loading = false

    if (result.data?.createConsultantInvoice.consultantInvoice?.id) {
      this.$emit("save")
      this.addSuccess("Invoice created successfully")
      this.reset()
    }
  }

  get profile(): Maybe<ConsultantProfileFragmentFragment> {
    const { profile } = useProfile()
    return profile
  }

  reset() {
    this.form = { ...this.defaultForm }
    this.$refs.observer && (this.$refs.observer as any).reset()
    this.expenses = []
  }

  async submitInvoice() {
    if (!this.updating) return
    this.loading = true

    const payload = { ...this.form }
    payload.expenses = payload.expenses.filter((e) => !e.id)

    const result = await this.mutate<SubmitConsultantInvoiceMutationMutation>({
      mutation: SubmitConsultantInvoiceMutation,
      variables: {
        ...payload,
        invoiceId: this.invoice?.id,
        billingAccountType:
          this.form.billingAccountType == "Payoneer"
            ? "ConsultantPayoneerAccount"
            : "ConsultantBankAccount",
      },
    })

    this.loading = false

    if (result?.data?.submitConsultantInvoice.consultantInvoice) {
      this.$emit("save")
      this.addSuccess("Invoice submitted!")
      this.reset()
    }
  }

  async onExpenseChange(key: string) {
    const expense = this.expenses.find((e) => e.key == key)

    if (expense && expense.id) {
      const { key, id, ...payload } = expense
      if (expense.uploadFile && !expense.uploadFile.key) {
        // existing upload file, remove
        payload.uploadFile = undefined
      }

      await this.mutate<
        UpdateConsultantMissionExpenseMutationMutation,
        UpdateConsultantMissionExpenseMutationMutationVariables
      >({
        mutation: UpdateConsultantMissionExpenseMutation,
        variables: { expenseId: expense.id, expense: { ...payload } },
      })
    }
  }

  async createExpense(invoiceId: number, payload: ConsultantMissionExpenseInput) {
    // TODO add UI indictors for errors
    await this.mutate<
      CreateConsultantMissionExpenseMutationMutation,
      CreateConsultantMissionExpenseMutationMutationVariables
    >({
      mutation: CreateConsultantMissionExpenseMutation,
      variables: { invoiceId: invoiceId, expense: { ...payload } },
    })
  }

  @Watch("invoice", { immediate: true })
  populateForm() {
    if (this.invoice) {
      this.form.billingAccountType =
        this.invoice.billingAccount?.__typename == "ConsultantPayoneerAccount" ? "Payoneer" : "Bank"

      this.form.billingAccountId = this.invoice.billingAccount?.id

      this.expenses = this.invoice.invoiceItems
        .filter((e) => e.type == "expense")
        .map((e) => {
          return {
            id: e.id,
            key: this.generateRand(4),
            description: e.description,
            quantity: e.quantity,
            unitCost: e.unitCost,
            uploadFile: e.uploadFile as unknown as UploadFileInput,
          }
        })
    }
  }
}
